
//This should be a react component if it returns JSX.
//Also I REALLY dont think we need a helper componet that does this.. so lets revisit it.

//if department is Egyptian, display the date period
export const checkEgyptianPeriod = (departmentID, period) => {
	if (departmentID == 10) { //Egyptian Dept
		return (
			<div>
				{period}
			</div>
		)
	}
}