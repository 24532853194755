import { IArtworkMinimum } from "@metmuseum/types";
const ArtistCultureDisplayer = ({
  object,
  threshold = 2
}: IProps) => {
  if (object.allArtistsFormatted?.length > threshold) {
    return "Multiple artists/makers";
  } else if (object.allArtistsFormatted?.length > 0) {
    return object.allArtistsFormatted?.map(artist => <div key={artist}>{artist}</div>);
  } else if (object.primaryArtist) {
    return object.primaryArtist;
  } else if (object.culture) {
    return object.culture;
  }
};
interface IProps {
  object: IArtworkMinimum;
  threshold?: number;
}
export default ArtistCultureDisplayer;