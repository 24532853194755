"server only";

import { COLLECTION_API_OBJECT_PAGE_ENDPOINT, IMPERVA_SAFELIST_SECRET } from "lib/constants.mjs";
import { COLLECTION_API_OBJECT_ENDPOINT } from "lib/constants.mjs";
import fetchWithErrorLogging from "lib/fetchWithErrorLogging";

export const getCollectionHeaders = () => new Headers({
	'x-the-met-safelist': IMPERVA_SAFELIST_SECRET,
	'Authorization': `Bearer ${process.env.COLLECTION_API_AUTH_TOKEN}`
});

export const getCollectionData = async (id: number) => {
	const data = await fetchWithErrorLogging(`${COLLECTION_API_OBJECT_ENDPOINT}/${id}`, {
		headers: getCollectionHeaders()
	});
	return data;
}

export const getCollectionImages = async (id: number) => {
	const obj = await getCollectionData(id);
	return obj?.media?.images;
}

export const getObjectPageData = async (id: number) => {
	const data = await fetchWithErrorLogging(`${COLLECTION_API_OBJECT_PAGE_ENDPOINT}/${id}`, {
		headers: getCollectionHeaders()
	});
	return data;
}