"use client";

/* eslint-disable @next/next/no-img-element */
import { getObjectPagePath, lofiArtworkObjectModel } from "helpers/collection";
import NoImage from "assets/icons/no-image.svg";
import styles from "./object-basics.module.scss";
import ArtistCultureDisplayer from "components/collection/artist-culture-displayer";
import RightsRestrictedMessage from "components/collection/rights-restricted-message";
import ObjectTitle from "components/collection/object-title";
import ObjectInfoTable from "components/collection//object-info-table";
import ChevronR from "assets/icons/chevron-r.svg";
import LinkButton from "components/controls/links/link-button";
import ObjectLabel from "components/collection/object-label";
import { IArtworkMinimum } from "@metmuseum/types";
import { usePathname } from "next/navigation";
import gtmEventHandler from "lib/gtmEventHandler";
const ObjectBasics = ({
  object,
  packageID,
  forceKioskMode = false
}: ObjectBasicsProps) => {
  const pathname = usePathname();
  const isKioskMode = forceKioskMode || pathname?.startsWith?.("/kiosk");
  const formattedObject = lofiArtworkObjectModel(object);
  const {
    image,
    imageAlt,
    primaryImageRestricted,
    date
  } = formattedObject;

  // Function to extract section and case number from the URL
  function extractValuesFromURL(url) {
    const parts = url.split('/');
    const section = parts[parts.length - 3];
    const caseNumber = parts[parts.length - 1];
    return {
      section: section,
      caseNumber: caseNumber
    };
  }

  // Extract values from the pathname
  let {
    section,
    caseNumber
  } = extractValuesFromURL(pathname);
  switch (section) {
    case 'greek-and-roman':
      section = 'The Greek and Roman Study Collection';
      break;
    case 'luce':
      section = 'Henry R. Luce Center for the Study of American Art';
      break;
    default:
      section = null;
      break;
  }
  const handleLinkClick = () => {
    if (section) {
      gtmEventHandler.pushToDataLayer({
        event: 'click_button',
        Button_Clicked: 'View Full Object Record',
        Content_Type: 'Gallery Viewer',
        Content_Name: section,
        Case_Number: caseNumber,
        Object_Title: formattedObject?.title,
        Object_ID: formattedObject?.objectID,
        Accession_Number: formattedObject?.accessionNumber
      });
    }
  };
  return <div className={styles.content} data-sentry-component="ObjectBasics" data-sentry-source-file="index.tsx">
		<figure className={styles.imageWrapper}>
			{image ? <img className={styles.image} alt={imageAlt} loading="lazy" src={image} /> : <div className={styles.imageWrapper}>
					<NoImage className={styles.noImage} />
				</div>}
			{primaryImageRestricted && <RightsRestrictedMessage />}
		</figure>

		<div className={styles.textWrapper}>
			<div className={styles.text}>

				<div>
					<ObjectTitle title={formattedObject?.title} data-sentry-element="ObjectTitle" data-sentry-source-file="index.tsx" />
					<div className={styles.attribution}>
						<div><ArtistCultureDisplayer object={formattedObject} threshold={3} data-sentry-element="ArtistCultureDisplayer" data-sentry-source-file="index.tsx" /></div>
						{date && <div>{date}</div>}
					</div>
				</div>

				<ObjectLabel object={object} packageID={packageID} isKioskMode={isKioskMode} data-sentry-element="ObjectLabel" data-sentry-source-file="index.tsx" />

				<ObjectInfoTable object={formattedObject} data-sentry-element="ObjectInfoTable" data-sentry-source-file="index.tsx" />
				{!isKioskMode && <LinkButton prefetch={false} rightIcon={<ChevronR />} text="View Full Object Record" href={getObjectPagePath(object?.objectID)} onClick={handleLinkClick} />}
			</div>
		</div>
	</div>;
};
interface ObjectBasicsProps {
  object: IArtworkMinimum;
  packageID: number;
  forceKioskMode?: boolean;
}
export default ObjectBasics;